import React, {useRef} from "react"
import Layout from "../../../../../components/layout"
import SEO from "../../../../../components/seo"



const ElastomericBearings = () => {
  //javascript
  const title = 'Elastomeric Bearings';
  const metatitle = 'Elastomeric Bearings';
  const description = 'Elastomeric Bearings : Limit states and threshold values ';
  const metadescription = description;
  const style2 = {
    marginBottom: 80
  }

  const scrollToRef = (ref) => window.scrollTo({
		left:0,
		top:ref.current.offsetTop,
		behavior: 'smooth'
	});
  const Ref1 = useRef(null);
  const Ref2 = useRef(null);
  const Ref3 = useRef(null);
  const Ref4 = useRef(null);
  const Ref5 = useRef(null);
  const Ref6 = useRef(null);
  const Ref7 = useRef(null);
  const Ref8 = useRef(null);
  const Ref9 = useRef(null);
  const Ref10 = useRef(null);
  const Ref11 = useRef(null);

	const executeScroll = (e, ref) => {
		e.preventDefault();
		scrollToRef(ref);
  }
  

  return(
    <Layout bodyclass="ls-experimental" title={title}>
      <SEO
        title={metatitle}
        description={metadescription}
      />
      <nav className="anchornav">
        <ul>
          <li>
            <a className="more actclass" href="../elastomeric-bearings">Elastomeric Bearings</a>
          </li>
          <li>
            <a className="more" href="../lead-rubber-bearings">Lead Rubber Bearings (LRB)</a>
          </li>
          <li>
            <a className="more" href="../elastomeric-bearings-ptfe">Elastomeric Bearings with PTFE Sheet</a>
          </li>
          <li>
            <a className="more" href="../steel-bearings">Steel Bearings</a>
          </li>
        </ul>
      </nav>
      <div className="content-table">
      <h2>Table 1: Elastomeric Bearings : Limit states and threshold values</h2>
      <h3>(R): limit state definition based on soa ,(E): Experimental, (S): Simulation or analysis</h3>
      <table style={style2}>
        <thead>
          <tr>
            <th colSpan={4}>Engineering Demand Parameter: Shear deformation γ (%)</th>
          </tr>
          <tr>
            <th>Reference</th>
            <th>Limit State</th>
            <th>Shear deformation (γ)</th>
            <th>Description</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td rowSpan={2}><button onClick={ e => executeScroll(e, Ref1)}>[1]</button>. (E) Mori, A., Moss, P.J., Carr, A. J., Cooke, N. (1997)<br/><a target="_blank" href="https://dx.doi.org/10.12989/sem.1997.5.4.451">https://dx.doi.org/10.12989/sem.1997.5.4.451</a></td>
            <td>LS1</td>
            <td>150%</td>
            <td>Yielding of steel plates</td>
          </tr>
          <tr>
            <td>LS4</td>
            <td>300%</td>
            <td>Bearing failure</td>
          </tr>
          <tr className="even">
            <td><button onClick={ e => executeScroll(e, Ref2)}>[2]</button>. (E) Bousias, E., Palios, X., Alexakis Ch., Strepelias, E., Fardis, M., Raptopoulos, S. (2008)</td>
            <td>LS3</td>
            <td>150%</td>
            <td>Detachment of elastomers and steel plates</td>
          </tr>
          <tr>
            <td rowSpan={4}><button onClick={ e => executeScroll(e, Ref3)}>[3]</button>. (R) Cardone, D. (2013) <a href="https://doi.org/10.1002/eqe.2396" target="blank">https://doi.org/10.1002/eqe.2396</a></td>
            <td>LS1</td>
            <td>150%, bolted pads γ(d<sub>fr</sub>), slipping unbolted pads γ(d<sub>pad</sub>/3), rolling-over unbolted pads</td>
            <td>Slipping between neoprene pads and concrete surfaces</td>
          </tr>
          <tr>
            <td>LS2</td>
            <td>200%, bolted pads γ(d<sub>fr</sub>+1/3(d<sub>pad</sub>-d<sub>fr</sub>)), slipping unbolted pads γ(d<sub>pad</sub>/2), rolling-over unbolted pads</td>
            <td>Shear deformation limit for linear viscoelasticbehvior of bolted neoprene pads</td>
          </tr>
          <tr>
            <td>LS3</td>
            <td>300%, bolted pads γ(d<sub>pad</sub>), slipping unbolted pads γ(d<sub>pad</sub>), rolling-over unbolted pads</td>
            <td>Rubber shear failure (bolted pads), slipping between neoprene concrete surface and roll-over (unbolted pads)</td>
          </tr>
          <tr>
            <td>LS4</td>
            <td>d<sub>uns</sub></td>
            <td>Unseating</td>
          </tr>
          <tr className="even">
            <td><button onClick={ e => executeScroll(e, Ref4)}>[4]</button>. (E) Konstantinidis et  al. (2008)</td>
            <td>LS3</td>
            <td>150%-225%</td>
            <td>Roll-over</td>
          </tr>
          <tr>
            <td rowSpan={3}><button onClick={ e => executeScroll(e, Ref5)}>[5]</button>. (E) LaFave et al. (2013)</td>
            <td>LS1</td>
            <td>53-85%</td>
            <td>Tension/shear failure of anchors</td>
          </tr>
          <tr>
            <td>LS2</td>
            <td>100-200%</td>
            <td>Initiation of slipping</td>
          </tr>
          <tr>
            <td>LS4</td>
            <td>400%</td>
            <td>Unseating</td>
          </tr>
          <tr className="even">
            <td><button onClick={ e => executeScroll(e, Ref6)}>[6]</button>. (E) Mori, A., Moss, P.J., Cooke, N., Carr, A. J. (1999) <a href="https://doi.org/10.1193%2F1.1586038" target="_blank">https://doi.org/10.1193%2F1.1586038</a></td>
            <td>LS3</td>
            <td>200%</td>
            <td>Bearing Uplift</td>
          </tr>
          <tr>
            <td><button onClick={ e => executeScroll(e, Ref7)}>[7]</button>. (E) Mori, A., Moss, P.J., Carr, A. J., Cooke, N. (1997) <a href="https://dx.doi.org/10.12989/sem.1997.5.4.451" target="_blank">https://dx.doi.org/10.12989/sem.1997.5.4.451</a></td>
            <td>LS2</td>
            <td>106-150%</td>
            <td>Yielding of steel plates</td>
          </tr>
          <tr className="even">
            <td rowSpan={4}><button onClick={ e => executeScroll(e, Ref8)}>[8]</button>. Moschonas, I. F., Kappos, A. J., Panetsos, P., Papadopoulos, V., Makarios, T., Thanopoulos, P. (2009) <a href="https://doi.org/10.1007/s10518-008-9077-2" target="_blank">https://doi.org/10.1007/s10518-008-9077-2</a></td>
            <td>LS1</td>
            <td>20%</td>
            <td>Minor damage</td>
          </tr>
          <tr className="even">
            <td>LS2</td>
            <td>150%</td>
            <td>Moderate damage</td>
          </tr>
          <tr className="even">
            <td>LS3</td>
            <td>200%</td>
            <td>Major damage</td>
          </tr>
          <tr className="even">
            <td>LS4</td>
            <td>500%</td>
            <td>Stability failure (toppling)</td>
          </tr>
          <tr>
            <td rowSpan={4}><button onClick={ e => executeScroll(e, Ref9)}>[9]</button>. (E) Nielson, B. (2005)</td>
            <td>LS1</td>
            <td>γ(Δ=30 mm), long. &amp; transv.</td>
            <td>Deformation of slight damage</td>
          </tr>
          <tr>
            <td>LS2</td>
            <td>γ(Δ=100 mm), long. &amp; transv.</td>
            <td>Possible dowel fracture</td>
          </tr>
          <tr>
            <td>LS3</td>
            <td>γ(Δ=150 mm), long. &amp; transv.</td>
            <td>Dowel fracture, repair requirement</td>
          </tr>
          <tr>
            <td>LS4</td>
            <td>γ(Δ=255 mm), long. &amp; transv.</td>
            <td>Unseating</td>
          </tr>
          <tr className="even">
            <td rowSpan={4}><button onClick={ e => executeScroll(e, Ref10)}>[10]</button>. (S) Stefanidou S. &amp; Kappos A.(2017) <a href="https://doi.org/10.1002/eqe.2774" target="_blank">https://doi.org/10.1002/eqe.2774</a></td>
            <td>LS1</td>
            <td>20%</td>
            <td>Initiation of nonlinear behaviour (yielding displacement of piers), potential yielding of anchor bolts and cracking of pedestals.</td>
          </tr>
          <tr className="even">
            <td>LS2</td>
            <td>100%</td>
            <td>Visible damage to the bearing; yield of steel shims</td>
          </tr>
          <tr className="even">
            <td>LS3</td>
            <td>200%</td>
            <td>Lift off at the edge of the bearing, uplift and rocking; may cause delamination, bonding failure between rubber layers and steel shim plates</td>
          </tr>
          <tr className="even">
            <td>LS4</td>
            <td>300%</td>
            <td>Lift-off, rotation; unseating, failure of bearings.</td>
          </tr>
          <tr>
            <td rowSpan={4}><button onClick={ e => executeScroll(e, Ref11)}>[11]</button>. (S+R) Zhang, J. and Huo, Y. (2009) <a href="https://doi.org/10.1016/j.engstruct.2009.02.017" target="_blank">https://doi.org/10.1016/j.engstruct.2009.02.017</a></td>
            <td>LS1</td>
            <td>100%</td>
            <td>Slight damage (strain limit for linear behavior of rubber)</td>
          </tr>
          <tr>
            <td>LS2</td>
            <td>150%</td>
            <td>Moderate damage</td>
          </tr>
          <tr>
            <td>LS3</td>
            <td>200%</td>
            <td>Extensive damage (Initiation of hardening of elastomeric material)</td>
          </tr>
          <tr>
            <td>LS4</td>
            <td>250%</td>
            <td>Complete damage (significant pounding or unseating)</td>
          </tr>
          
        </tbody>
      </table>
      </div>
      <div className="references">
        <h4>References</h4>
        <ol>
          <li ref={Ref1}>Aviram, A., Mackie, K. R., Stojadinovic, B. (2008). Effect of abutment modeling on the seismic response of bridge structures, Earthquake Engineering and Engineering Vibration, Vol. 7, No. 4, pp 395 – 402,  DOI: https://doi.org/10.1007/s11803-008-1008-3.</li>
          <li ref={Ref2}>Bousias, E., Palios, X., Alexakis, C., Strepelias, I., Fardis, M., Raptopoulos, S. (2008). Experimental and analytical study of seismically isolated bridges with or without additional damping, 3rd Hellenic Conference on Earthquake Engineering and Engineering Seismology, November 5-7, 2008 (in Greek).</li>
          <li ref={Ref3}>Cardone, D. (2013). Displacement limits and performance displacement profiles in support of direct displacement-based seismic assessment of bridges, Earthquake Engineering &amp; Structural Dynamics, DOI: http://dx.doi.org/10.1002/eqe.2396. </li>
          <li ref={Ref4}>Konstantinidis, D., J.M. Kelly, and N. Makris. (2008). Experimental Investigations on the Seismic Response of Bridge Bearings, Earthquake Engineering Research Center, Report No. EERC 2008-02, College of Engineering, University of California, Berkeley, CA.</li>
          <li ref={Ref5}>LaFave, J., Fahnestock, L., Foutch, D., Steelman, J., Revell, J., Filipov, E., Hajjar, J. (2013). Experimental Investigation of the Seismic Response of Bridge Bearings, Research Report No.  FHWA-ICT-13-002,  Illinois Center for Transportation. </li>
          <li ref={Ref6}>Mori, A., Moss, P. J., Cooke, N., Carr, A. J. (1999). The Behavior of Bearings Used for Seismic Isolation under Shear and Axial Load, Earthquake Spectra, Vol. 15, No. 2, pp 199-224, https://doi.org/10.1193%2F1.1586038. </li>
          <li ref={Ref7}>Mori, A., Moss, P. J., Carr, A. J., Cooke, N. (1997). Behaviour of laminated elastomeric bearings, Structural Engineering and Mechanics, Vol. 5, No. 4, pp 451-469, DOI: http://dx.doi.org/10.12989/sem.1997.5.4.451. </li>
          <li ref={Ref8}>Moschonas, I. F., Kappos, A. J., Panetsos, P., Papadopoulos, V., Makarios, T., Thanopoulos, P. (2009). Seismic fragility curves for Greek bridges: methodology and case studies, Bulletin of Earthquake Engineering, Vol. 7, pp 439-368, https://doi.org/10.1007/s10518-008-9077-2.</li>
          <li ref={Ref9}>Nielson, B. G. (2005). Analytical Fragility Curves for Highway Bridges in Moderate Seismic Zones, PhD Thesis, Georgia Institute of Technology, December, 2005.</li>
          <li ref={Ref10}>Stefanidou, S. &amp; Kappos A. (2017). Methodology for the development of bridge-specific fragility curves, Earthquake Engineering and Structural Dynamics, vol. 46, pp 73-93, https://doi.org/10.1002/eqe.2774. </li>
          <li ref={Ref11}>Zhang, J. and Huo, Y. (2009). Evaluating effectiveness and optimum design of isolation devices for highway bridges using the fragility function method, Engineering Structures, Vol. 31, pp 1648-1660, https://doi.org/10.1016/j.engstruct.2009.02.017. </li>
        
        </ol>  
      </div>
      
    </Layout>
  )
}

export default ElastomericBearings
